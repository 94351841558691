@import url("https://fonts.googleapis.com/css?family=Hind");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Glass+Antiqua");
@import url("https://fonts.googleapis.com/css?family=Parisienne");

.no-border {
  border: none !important;
}

/* Footer */
.og-section-text {
  padding: 50px 5vmin !important;
  align-items: center;
  font-family: "Hind" !important;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 2.4vmax;
  .h3 {
    text-transform: uppercase !important;
  }
  @media (min-width: 768px) {
    padding: 5vmax !important;
    font-size: 2.4vmin;
  }
  .btn-block {
    padding: 25px;
    color: #d39e00;
    font-weight: 800;
  }
  // @media (min-width: 768px) {
  //   padding: 50px 150px 50px 150px !important;
  // }
}

.og-section-btn {
  padding: 50px;
}
.no-padding {
  padding: 0px !important;
}

.og-section-title {
  h2 {
    // font-size: calc(12px + 5vmin);
    font-size: 6vh;
  }
  color: #d39e00;
  text-align: center;
  @media (max-width: 768px) {
    // font-size: 6vw;

    padding: 5vmax 2vmin;
    // h2 {
    //   font-size: calc(12px + 5vmin);
    // }
  }
  font-family: "Parisienne" !important;
}

.og-menu {
  background-color: #282c34 !important;
  h2 {
    color: white;
  }
}

.og-image-h {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: 100%;
  min-height: 40vh;
  max-width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.og-image-v {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 100%;
  max-height: 100%;
  min-height: 80vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.story {
  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    flex-direction: row;
  }

  /* Create four equal columns that sits next to each other */
  .column {
    flex: 33%;
    @media (max-width: 768px) {
      flex: 100%;
    }

    max-width: 100%;
    padding: 0 4px;
  }

  .column img {
    width: 100%;
    height: auto;
    margin-top: 8px;
    vertical-align: middle;
  }
}
.story-1 {
  background-image: url("../assets/images/story.jpg?size=1200");
}
.story-2 {
  background-image: url("../assets/images/titel_2.jpg?size=1200");
}
.story-3 {
  background-image: url("../assets/images/story_3.jpg?size=1200");
}
.team-1 {
  background-image: url("../assets/images/team_1.jpg?size=1200");
}
.team-2 {
  background-image: url("../assets/images/team_2.jpg?size=1200");

  background-position: left;
}
.menu {
  background-image: url("../assets/images/Gallerie/kitchen_3.jpg?size=1200");
}

.og-header-img {
  position: absolute;
  top: 0;
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 100%;

  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;

  background-image: linear-gradient(
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ),
    url("../assets/images/menu.jpg?size=1200");

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    
  transform: rotate(20deg) ;
    @media (min-width: 768px) {
      width: 30vw !important;
  }
  }
}
// @media (max-width: 480px) {
//   .og-header-img {
//     position: absolute;
//     /* Set rules to fill background */
//     min-height: 100%;
//     min-width: 100%;

//     /* Set up proportionate scaling */
//     width: 100%;
//     height: 100%;

//     background: url("../assets/images/5.jpg?size=300");

//     /* Center and scale the image nicely */
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//   }
// }

.og-header-text {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  font-size: calc(12px + 8vmin) !important;
  h2 {
    color: #d39e00;
    vertical-align: middle;
    font-family: "Parisienne" !important;
    font-weight: 300;
  }
  h1 {
    font-size: calc(10px + 4vmin) !important;
    color: white;
    vertical-align: middle;
    font-family: "Glass+Antiqua";
    font-weight: 300;
  }
  @media (min-width: 768px) {
    top: 30%;
  }
}
.gallery {
  margin-top: 50px;
  padding-top: 50px;
  margin-bottom: -25px;
  padding-bottom: 50px;
  background-color: #f6f5f3;

  .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create four equal columns that sits next to each other */
  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }

  .column img {
    width: 100%;
    height: auto;
    margin-top: 8px;
    vertical-align: middle;
  }

  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }
}
