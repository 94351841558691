.og-header {
  position: absolute;
  top: 0;
  /* Set rules to fill background */
  min-height: 80vh;
  min-width: 100%;
  background-color: #f6f5f3;
}

.og-header-title {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  font-size: calc(12px + 8vmin) !important;

  h2 {
    color: #d39e00;
    vertical-align: middle;
    font-family: "Parisienne" !important;
    font-weight: 300;
  }
  h1 {
    font-size: calc(10px + 4vmin) !important;
    color: black;
    vertical-align: middle;
    font-family: "Glass+Antiqua";
    font-weight: 300;
  }
  @media (min-width: 768px) {
    top: 30%;
  }
}

#kontakt {
  padding: 25px;
  @media (min-width: 768px) {
    padding: 100px;
  }
  h3 {
    padding: 15px;
  }
}
