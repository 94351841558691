@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.App {
  text-align: center;
}

.App-logo {
  // animation: App-logo-spin infinite 20s linear;
  height: 5vmin;
}
