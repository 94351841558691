@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Hind);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Glass+Antiqua);
@import url(https://fonts.googleapis.com/css?family=Parisienne);
@import url(https://fonts.googleapis.com/css?family=Hind);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Parisienne);
body, html {
  height: 100%;
  margin: 0;
}

body {
  color: #848789 !important;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center; }

.App-logo {
  height: 5vmin; }

.no-border {
  border: none !important; }

/* Footer */
.og-section-text {
  padding: 50px 5vmin !important;
  align-items: center;
  font-family: "Hind" !important;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 2.4vmax; }
  .og-section-text .h3 {
    text-transform: uppercase !important; }
  @media (min-width: 768px) {
    .og-section-text {
      padding: 5vmax !important;
      font-size: 2.4vmin; } }
  .og-section-text .btn-block {
    padding: 25px;
    color: #d39e00;
    font-weight: 800; }

.og-section-btn {
  padding: 50px; }

.no-padding {
  padding: 0px !important; }

.og-section-title {
  color: #d39e00;
  text-align: center;
  font-family: "Parisienne" !important; }
  .og-section-title h2 {
    font-size: 6vh; }
  @media (max-width: 768px) {
    .og-section-title {
      padding: 5vmax 2vmin; } }

.og-menu {
  background-color: #282c34 !important; }
  .og-menu h2 {
    color: white; }

.og-image-h {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: 100%;
  min-height: 40vh;
  max-width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.og-image-v {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: 100%;
  max-height: 100%;
  min-height: 80vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.story {
  /* Create four equal columns that sits next to each other */ }
  .story .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    flex-direction: row; }
  .story .column {
    flex: 33% 1;
    max-width: 100%;
    padding: 0 4px; }
    @media (max-width: 768px) {
      .story .column {
        flex: 100% 1; } }
  .story .column img {
    width: 100%;
    height: auto;
    margin-top: 8px;
    vertical-align: middle; }

.story-1 {
  background-image: url(/images/story-1200.jpg); }

.story-2 {
  background-image: url(/images/titel_2-1182.jpg); }

.story-3 {
  background-image: url(/images/story_3-1080.jpg); }

.team-1 {
  background-image: url(/images/team_1-1200.jpg); }

.team-2 {
  background-image: url(/images/team_2-1200.jpg);
  background-position: left; }

.menu {
  background-image: url(/images/kitchen_3-1152.jpg); }

.og-header-img {
  position: absolute;
  top: 0;
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 100%;
  /* Set up proportionate scaling */
  width: 100%;
  height: 100%;
  background-image: linear-gradient(black, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), url(/images/menu-1200.jpg);
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .og-header-img img {
    -webkit-transform: rotate(20deg);
            transform: rotate(20deg); }
    @media (min-width: 768px) {
      .og-header-img img {
        width: 30vw !important; } }

.og-header-text {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: calc(12px + 8vmin) !important; }
  .og-header-text h2 {
    color: #d39e00;
    vertical-align: middle;
    font-family: "Parisienne" !important;
    font-weight: 300; }
  .og-header-text h1 {
    font-size: calc(10px + 4vmin) !important;
    color: white;
    vertical-align: middle;
    font-family: "Glass+Antiqua";
    font-weight: 300; }
  @media (min-width: 768px) {
    .og-header-text {
      top: 30%; } }

.gallery {
  margin-top: 50px;
  padding-top: 50px;
  margin-bottom: -25px;
  padding-bottom: 50px;
  background-color: #f6f5f3;
  /* Create four equal columns that sits next to each other */
  /* Responsive layout - makes a two column-layout instead of four columns */
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */ }
  .gallery .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px; }
  .gallery .column {
    flex: 25% 1;
    max-width: 25%;
    padding: 0 4px; }
  .gallery .column img {
    width: 100%;
    height: auto;
    margin-top: 8px;
    vertical-align: middle; }
  @media screen and (max-width: 800px) {
    .gallery .column {
      flex: 50% 1;
      max-width: 50%; } }
  @media screen and (max-width: 600px) {
    .gallery .column {
      flex: 100% 1;
      max-width: 100%; } }

.og-header {
  position: absolute;
  top: 0;
  /* Set rules to fill background */
  min-height: 80vh;
  min-width: 100%;
  background-color: #f6f5f3; }

.og-header-title {
  position: absolute;
  top: 25%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: calc(12px + 8vmin) !important; }
  .og-header-title h2 {
    color: #d39e00;
    vertical-align: middle;
    font-family: "Parisienne" !important;
    font-weight: 300; }
  .og-header-title h1 {
    font-size: calc(10px + 4vmin) !important;
    color: black;
    vertical-align: middle;
    font-family: "Glass+Antiqua";
    font-weight: 300; }
  @media (min-width: 768px) {
    .og-header-title {
      top: 30%; } }

#kontakt {
  padding: 25px; }
  @media (min-width: 768px) {
    #kontakt {
      padding: 100px; } }
  #kontakt h3 {
    padding: 15px; }

footer {
  min-height: 30vh;
  padding-top: 25px;
  margin-top: 25px;
  line-height: 1.7rem;
  background-color: #282c34;
  color: white !important; }
  footer a {
    color: #d39e00 !important; }
  footer p {
    padding: 10px 0px; }
  footer .contact .adress p {
    padding: 0px !important; }
  footer .contact .row {
    padding: 40px 14px; }
  footer .demo-bg {
    background: #d39e00;
    margin-top: 60px; }
  footer .business-hours:before {
    content: ""; }
  footer .business-hours .title {
    font-size: 20px;
    color: #bbb;
    text-transform: uppercase;
    padding-left: 5px;
    border-left: 4px solid #ffac0c; }
  footer .business-hours .row {
    padding: 40px 14px; }
  footer .business-hours li {
    padding: 10px 0px;
    color: #fff;
    line-height: 30px; }
  footer .business-hours li:last-child {
    border-bottom: none; }
  footer .business-hours .opening-hours li.today {
    color: #ffac0c; }

.App-header {
  min-height: 100vh;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff !important; }
  @media (min-width: 1024px) {
    .App-header {
      font-size: 1.6rem; } }

.navbar {
  z-index: 2001;
  display: flex !important;
  align-items: flex-start !important;
  background-color: rgba(0, 0, 0, 0.9) !important; }
  .navbar a {
    color: #d39e00 !important; }
    .navbar a i {
      color: #ffffff !important; }
  .navbar button-outline-danger {
    color: #ffffff !important; }
  .navbar button:focus {
    outline: none; }

.navbar-blur {
  transition: 0.5s ease-in-out;
  background: rgba(0, 0, 0, 0.9); }

.navbar-standard {
  transition: 0.5s ease-in-out; }

.no-border {
  border: none !important; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

