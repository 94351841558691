@import url("https://fonts.googleapis.com/css?family=Hind");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Parisienne");

.App-header {
  // background-color: #282c34;
  min-height: 100vh;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // font-size: calc(10px + 2vmin);
  @media (min-width: 1024px) {
    font-size: 1.6rem;
  }

  color: #ffffff !important;
}
.navbar {
  z-index: 2001;
  display: flex !important;
  align-items: flex-start !important;
  // @media (min-width: 1024px) {
  //   padding: 0 !important;
  // }
  background-color: rgba(0, 0, 0, 0.9) !important;

  a {
    color: #d39e00 !important;
    i {
      color: #ffffff !important;
    }
  }

  button-outline-danger {
    color: #ffffff !important;
    // outline-color: #d39e00;
  }
  button:focus {
    outline: none;
  }
}

.navbar-blur {
  transition: 0.5s ease-in-out;
  background: rgba(0, 0, 0, 0.9);
}
.navbar-standard {
  transition: 0.5s ease-in-out;
  // background: transparent !important;
}

.no-border {
  border: none !important;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
