footer {
  min-height: 30vh;
  padding-top: 25px;
  margin-top: 25px;
  line-height: 1.7rem;
  background-color: #282c34;
  color: white !important;
  a {
    color: #d39e00 !important;
  }

  p {
    padding: 10px 0px;
  }

  .contact {
    .adress p {
      padding: 0px !important;
    }
    .row {
      padding: 40px 14px;
    }
  }

  .footer-copyright {
    // background-color: black;
  }

  .demo-bg {
    background: #d39e00;
    margin-top: 60px;
  }
  .business-hours {
    // background: #222;
    // margin-top: -15px;
    // position: relative;
  }
  .business-hours:before {
    content: "";
    // width: 23px;
    // height: 23px;
    // background: #111;
    // position: absolute;
    // top: 5px;
    // left: -12px;
    // transform: rotate(-45deg);
    // z-index: -1;
  }
  .business-hours .title {
    font-size: 20px;
    color: #bbb;
    text-transform: uppercase;
    padding-left: 5px;
    border-left: 4px solid #ffac0c;
  }
  .business-hours .row {
    padding: 40px 14px;
  }

  .business-hours li {
    padding: 10px 0px;
    color: #fff;
    line-height: 30px;
    // border-bottom: 1px solid #d39e00;
  }
  .business-hours li:last-child {
    border-bottom: none;
  }
  .business-hours .opening-hours li.today {
    color: #ffac0c;
  }
}
